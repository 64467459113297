<template>
  <v-container class="pa-0">
    <v-list three-line>
      <v-radio-group hide-details>
        <transition-group name="fade" tag="div">
          <kurcc-staff-list-item v-for="(member, index) in members" :key="index"
                                 :group-id="groupId" :member="member" :multi-select="multiSelect"
                                 :show-select-button="showSelectButton" @check-changed="changeTodayList($event, index)">
            <v-divider v-if="index !== members.length -1" slot="divider"/>
          </kurcc-staff-list-item>
        </transition-group>
      </v-radio-group>
    </v-list>
    <kurcc-infinite-loading :filter="filter" :items.sync="members" action-name="getAllStaff"/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccStaffList',
  props: {
    filter: {
      type: Array,
      required: false
    },
    multiSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    showSelectButton: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedIds: {
      type: Array,
      required: false
    },
    groupId: {
      required: false
    }
  },
  components: {
    KurccStaffListItem: () => import('@/modules/app/components/KurccStaffListItem'),
    KurccInfiniteLoading: () => import('@/modules/app/components/KurccInfiniteLoading')
  },
  data () {
    return {
      members: []
    }
  },
  methods: {
    changeTodayList (value, index) {
      const ids = [...this.selectedIds]

      if (value) {
        if (typeof value === 'object') {
          ids.push(value.id)
        } else {
          ids.push(this.members[index].id)
        }
      } else {
        if (typeof value === 'object') {
          while (ids.length) {
            ids.pop()
          }
        } else {
          ids.splice(ids.findIndex(id => id === this.members[index].id), 1)
        }
      }

      this.$emit('update:selected-ids', ids)
    }
  }
}
</script>
